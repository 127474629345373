'use client';

import FilledButton, { PillButton } from '@components/common/Button';
import {
  DecorativeLabel,
  H3Text,
  H5Text,
  LargeText,
  RegularText,
} from '@components/common/Typography';
import { FullImage } from '@components/common/Util';
import VerticalSpace from '@components/common/VerticalSpace';
import { Orbitron } from 'next/font/google';
import localFont from 'next/font/local';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { getClient } from 'utils/microcms';
import { useNavigate } from 'utils/navigate';
import {
  countDownContainer,
  countDownDigit,
  countDownDigitContainer,
  indicatorBackground,
  indicatorBar,
  indicatorContainer,
  indicatorRelative,
  levelText,
  levelUpContainer,
  preOrderContainer,
  rocket,
} from './_pre-order.css';

type Level = 1 | 2 | 3;

type Sales = {
  progress: number;
  level: Level;
};

const orbitron = Orbitron({
  subsets: ['latin'],
  weight: '700',
});

const digitFont = localFont({
  src: './_digit_font.ttf',
  display: 'swap',
});

// 12/13 AM11:30
const DISTRIBUTION_DATE = new Date('2024-12-13T11:30:00+09:00');

const salesLevels = {
  1: '打ち上げ',
  2: '成層圏突破',
  3: '大気圏突破',
};

export default function PreOrder() {
  const { navigateToExternal } = useNavigate();
  return (
    <div className={preOrderContainer}>
      <FullImage src="/images/top/pre-order/img_theme.jpg" alt="CNPトレカPASS" />
      <H3Text>小さな革命 - 次元の来訪者 -</H3Text>
      <H5Text>CNPトレーディングカードゲーム</H5Text>
      <VerticalSpace size="11"></VerticalSpace>
      <CountDown />
      <VerticalSpace size="11" />
      <H5Text>プレオーダー期間</H5Text>
      <RegularText>
        1次販売：12/13(Fri)　11:30 - 12/15(Sun) 21:00
        <br />
        2次販売：12/16(Mon)12:00 - 12/18(Wed) 13:00
      </RegularText>
      <VerticalSpace size="26"></VerticalSpace>
      <SalesIndicator />
      <VerticalSpace size="40"></VerticalSpace>
      <div
        style={{
          padding: '0 16px',
          width: '100%',
          maxWidth: '500px',
        }}
      >
        <FilledButton onClick={() => navigateToExternal('notion')}>販売情報まとめ</FilledButton>
      </div>
      <VerticalSpace size="26"></VerticalSpace>
      <PillButton onClick={() => navigateToExternal('x-campaign')}>
        <H5Text>Xで応援する</H5Text>
      </PillButton>
    </div>
  );
}

function CountDown() {
  const [timeLeft, setTimeLeft] = useState(0);
  useEffect(() => {
    const timerId = setInterval(() => {
      setTimeLeft(DISTRIBUTION_DATE.getTime() - Date.now());
    }, 1000);
    return () => clearInterval(timerId);
  });

  const [days, hours, minutes, seconds] = [
    Math.floor(timeLeft / (1000 * 60 * 60 * 24)),
    Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60)),
    Math.floor((timeLeft % (1000 * 60)) / 1000),
  ];
  if (timeLeft <= 0) {
    return <div></div>;
  }

  return (
    <div className={countDownContainer}>
      <DecorativeLabel>販売開始まで</DecorativeLabel>
      <CountDownDigit unit="日" value={days} />
      <CountDownDigit unit="時" value={hours} />
      <CountDownDigit unit="分" value={minutes} />
      <CountDownDigit unit="秒" value={seconds} />
    </div>
  );
}

function CountDownDigit({ unit, value }: { unit: string; value: number }) {
  return (
    <div className={countDownDigitContainer}>
      <div className={`${digitFont.className} ${countDownDigit}`}>{('00' + value).slice(-2)}</div>
      <DecorativeLabel>{unit}</DecorativeLabel>
    </div>
  );
}

async function getSalesProgress(): Promise<Sales> {
  const client = getClient();
  const rawSales = await client.get({ endpoint: 'sales' });
  const sales: number = rawSales[202412];
  const level = Math.floor(sales / 5000) + 1;

  if (level > 3) {
    return {
      progress: 1.0,
      level: 3,
    };
  }

  const totalSalesForCurrentLevel = level * 5000;
  const progress = sales / totalSalesForCurrentLevel;

  return {
    progress: progress,
    level: level as 1 | 2 | 3,
  };
}

function SalesIndicator() {
  const [level, setLevel] = useState<Level | undefined>(undefined);
  const [progress, setProgress] = useState(0.0);

  useEffect(() => {
    const progressBarForeground = document.getElementById('progress-bar');
    const rocket = document.getElementById('rocket');

    const updateIndicator = async () => {
      const sales = await getSalesProgress();

      setLevel(sales.level);
      setProgress(sales.progress);

      if (!progressBarForeground || !rocket) {
        return;
      }
      progressBarForeground.style.clipPath = `inset(0px ${292 - 292 * sales.progress}px 0px 0px)`;
      rocket.style.left = `${-292 + 584 * sales.progress}px`;
    };
    updateIndicator();
  });

  if (level === undefined) {
    return null;
  }
  return (
    <>
      <div className={indicatorContainer}>
        <div className={indicatorRelative}>
          <Image
            src={`/images/top/pre-order/img_indicator_${level}.png`}
            width="375"
            height="190"
            alt=""
            className={indicatorBackground}
          />
          <Image
            src={`/images/top/pre-order/img_bar_${level}.png`}
            width="292"
            height="5"
            alt=""
            id="progress-bar"
            className={indicatorBar}
          />
          <Image
            src={`/images/top/pre-order/img_rocket.png`}
            width="64"
            height="36"
            alt=""
            id="rocket"
            className={rocket}
          />
        </div>
      </div>
      <div className={levelUpContainer}>
        <LargeText>{salesLevels[level]}まであと</LargeText>
        <div className={`${orbitron.className} ${levelText}`}>
          {100 - Math.floor(progress * 100)}%
        </div>
      </div>
    </>
  );
}

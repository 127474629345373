import {
  boldText,
  decorativeLabel,
  h1Text,
  h2Text,
  h3Text,
  h5Text,
  label,
  largeText,
  memberHeaderText,
  memberSubHeaderText,
  regularText,
} from './Typography.css';

export function H1Text({ children }: { children: React.ReactNode }) {
  return <div className={h1Text}>{children}</div>;
}

export function H2Text({ children }: { children: React.ReactNode }) {
  return <div className={h2Text}>{children}</div>;
}

export function H3Text({ children }: { children: React.ReactNode }) {
  return <div className={h3Text}>{children}</div>;
}

export function H5Text({ children, align }: { children: React.ReactNode; align?: 'start' }) {
  return (
    <div
      className={h5Text}
      style={{
        textAlign: align || 'center',
      }}
    >
      {children}
    </div>
  );
}

export function MemberHeaderText({ children }: { children: React.ReactNode }) {
  return <div className={memberHeaderText}>{children}</div>;
}

export function MemberSubHeaderText({ children }: { children: React.ReactNode }) {
  return <div className={memberSubHeaderText}>{children}</div>;
}

export function LargeText({ children }: { children: React.ReactNode }) {
  return <div className={largeText}>{children}</div>;
}

export function BoldText({ children }: { children: React.ReactNode }) {
  return <div className={boldText}>{children}</div>;
}

export function RegularText({ children }: { children: React.ReactNode }) {
  return <div className={regularText}>{children}</div>;
}

export function Label({ children }: { children: React.ReactNode }) {
  return <div className={label}>{children}</div>;
}

export function DecorativeLabel({ children }: { children: React.ReactNode }) {
  return <div className={decorativeLabel}>{children}</div>;
}

import { useRouter } from 'next/navigation';

export type InternalDestination =
  | 'top'
  | 'top/benefits'
  | 'top/news'
  | 'top/appraisal'
  | 'top/about-cnp'
  | 'top/questions'
  | 'company'
  | 'policy/privacy-policy'
  | 'policy/social-media-policy'
  | 'policy/inquiry'
  | 'appraisal'
  | 'products'
  | 'products/$id';

const InternalPath: Record<InternalDestination, string> = {
  top: '/',
  'top/benefits': '/#benefits',
  'top/news': '/#news',
  'top/appraisal': '/#appraisal',
  'top/about-cnp': '/#about-cnp',
  'top/questions': '/#questions',
  company: '/company',
  'policy/privacy-policy': '/policy#privacy-policy',
  'policy/social-media-policy': '/policy#social-media-policy',
  'policy/inquiry': '/policy#inquiry',
  appraisal: '/appraisal',
  products: '/products',
  'products/$id': '/products/$id',
};
type ExternalDestination =
  | 'notion'
  | 'shop'
  | 'card-list'
  | 'cnp-pass'
  | 'note'
  | 'x'
  | 'x-share'
  | 'x-campaign'
  | 'youtube'
  | 'share-to-line';

const ExternalPath: Record<ExternalDestination, string> = {
  notion:
    'https://subsequent-coconut-9a4.notion.site/1466e8d5d57b806190e0f236c7a8776f?v=1466e8d5d57b8103abce000cbb65bb8e',
  shop: 'https://cnptcg.shop/',
  'cnp-pass': 'https://market.alpha-u.io/collections/CNP_TCG_PASS',
  'card-list': 'https://app.cnptcg.monolithos.co.jp/cards	',
  note: 'https://note.com/monolithos_cnp',
  x: 'https://x.com/Monolithos_CNP',
  'x-share':
    'https://x.com/intent/post?url=https://cnptcg.monolithos.co.jp&text=CNPトレーディングカードゲーム 公式サイト',
  'x-campaign':
    'https://x.com/intent/post?url=https%3A%2F%2Fcnptcg.monolithos.co.jp&text=CNP%E3%83%88%E3%83%AC%E3%82%AB+l+%E5%B0%8F%E3%81%95%E3%81%AA%E9%9D%A9%E5%91%BD%E2%88%92%E6%AC%A1%E5%85%83%E3%81%AE%E8%A8%AA%E5%95%8F%E8%80%85-%E3%81%8C12%2F13%E3%81%AB%E3%81%84%E3%82%88%E3%81%84%E3%82%88%E8%B2%A9%E5%A3%B2%E5%8F%97%E4%BB%98%E9%96%8B%E5%A7%8B+%23%E6%96%B0%E3%81%97%E3%81%84%E3%83%88%E3%83%AC%E3%82%AB+%23CNP%E3%83%88%E3%83%AC%E3%82%AB',
  youtube:
    'https://www.youtube.com/@CNP%E3%83%88%E3%83%AC%E3%82%AB%E5%85%AC%E5%BC%8F%E3%83%81%E3%83%A3%E3%83%B3%E3%83%8D%E3%83%AB',
  'share-to-line':
    'https://social-plugins.line.me/lineit/share?url=https://cnptcg.monolithos.co.jp',
};
export const useNavigate = () => {
  const router = useRouter();

  const navigateToInternal = (destination: InternalDestination, id?: string) => {
    const path = InternalPath[destination].replace('$id', id || '');
    router.push(path);
  };

  const navigateToExternal = (destination: ExternalDestination, newTab: boolean = true) => {
    if (newTab) {
      window.open(ExternalPath[destination], '_blank');
    } else {
      window.location.href = ExternalPath[destination];
    }
  };

  const navigateToExternalFlexible = (url: string) => {
    window.open(url, '_blank');
  };

  return {
    navigateToInternal,
    navigateToExternal,
    navigateToExternalFlexible,
  };
};

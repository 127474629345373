'use client';

import { TextLink } from '@components/common/Button';
import { SectionContainer } from '@components/common/Container';
import { H2Text, RegularText } from '@components/common/Typography';
import VerticalSpace from '@components/common/VerticalSpace';
import Image from 'next/image';
import { useNavigate } from 'utils/navigate';
import { gridStyle, imageStyle, topicStyle } from './_news.css';

type News = {
  image: string;
  imageSize: {
    width: number;
    height: number;
  };
  text: string;
  url: string;
};

export default function News() {
  const newses: News[] = [
    {
      image: '/images/news/img_news_1.jpg',
      imageSize: {
        width: 1686,
        height: 1192,
      },
      text: 'CNPアートトレカ β に関するお知らせとFAQ（7/5 19:01更新）',
      url: 'https://note.com/monolithos_cnp/n/n8c59d6912ae4',
    },
    {
      image: '/images/news/img_news_2.webp',
      imageSize: {
        width: 1684,
        height: 1190,
      },
      text: '「CNPトレカパス」、販売開始直後に300点を完売',
      url: 'https://prtimes.jp/main/html/rd/p/000000002.000144873.html',
    },
    {
      image: '/images/news/img_news_3.jpg',
      imageSize: {
        width: 1684,
        height: 1194,
      },
      text: 'CNP、トレーディングカードゲーム事業を展開するモノリス株式会社と協業を開始',
      url: 'https://prtimes.jp/main/html/rd/p/000000088.000012092.html',
    },
    {
      image: '/images/news/img_news_4.webp',
      imageSize: {
        width: 1920,
        height: 1280,
      },
      text: 'CNP（CryptoNinja Partners）の流通総額が発売15ヶ月で13,000ETHを突破',
      url: 'https://prtimes.jp/main/html/rd/p/000000072.000012092.html',
    },
  ];

  const { navigateToExternal, navigateToExternalFlexible } = useNavigate();

  return (
    <SectionContainer id="news">
      <VerticalSpace size="60"></VerticalSpace>
      <H2Text>ニュース</H2Text>
      <VerticalSpace size="18"></VerticalSpace>
      <div className={gridStyle}>
        {newses.map((news, index) => (
          <div
            className={topicStyle}
            key={index}
            onClick={() => navigateToExternalFlexible(news.url)}
          >
            <Image
              src={news.image}
              alt=""
              className={imageStyle}
              width={news.imageSize.width}
              height={news.imageSize.height}
            />
            <RegularText>{news.text}</RegularText>
          </div>
        ))}
      </div>
      <VerticalSpace size="18"></VerticalSpace>
      <TextLink
        onClick={() => {
          navigateToExternal('note');
        }}
      >
        公式CNPトレカnoteはこちら
      </TextLink>
    </SectionContainer>
  );
}

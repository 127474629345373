import "../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22app%2F_pre-order.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA62UwW6cMBCG7%2FsUI1WVEqleGQrZxbn0kj5Eb14w7DTGtuyhYVvtu1c2DSHbTbpqIg6A7fn%2Bf4ZffLlXh9bLXgUIocBttYVfKwD%2BMd0AyEsTWut7MT1qSeqKu%2FETcDdeg7ckSX274o3qrm9XAMcVQF6%2BWp3H6nxZnS2rywu08xe1NxdoP3POnoln%2FA2dH1fraYY8IRoMTsuDgFarMZ6Id9agVzWhNQJqq4fexB2psTMMSfVBQK0MKR%2BXH7ChvUimbp%2Fo2Xn6C4zvQyBsD6y2hpSh5VYnnYDsxo1%2FaQE42TRoOgGVG%2BczO1nfd94OpmG11dYL%2BMA5jzuPr23bLpzmFzhNQ1GmWZR9TmWzQvn17q5K87OGWMCfSkBeTI7S0oPCbk8CiuhlphSJctLW%2Fs%2FRrOKvtzRjyoRxNuD00bzSkvCHOjOzE%2FiMuDlByF2weqCEQBMUCeDTFVd66TuMpwayC8jmDRAAsk5AsZ16rjU65mS0nipjqiGvcjfGaKd4L4Sr9xPWqiUBLEmlIBjs5UR9%2FPmsswBoWjQYFWYT8r0yfz7mbGeJbC%2F%2BO%2Bm75O9fAd1MCI1GsTks6zLNSY3EUjtPbo%2B%2FAdYdZWadBQAA%22%7D"
export var countDownContainer = 'ss4i891';
export var countDownDigit = 'ss4i893';
export var countDownDigitContainer = 'ss4i892';
export var indicatorBackground = 'ss4i896';
export var indicatorBar = 'ss4i897';
export var indicatorContainer = 'ss4i894';
export var indicatorRelative = 'ss4i895';
export var levelText = 'ss4i89b';
export var levelUpContainer = 'ss4i89a';
export var preOrderContainer = 'ss4i890';
export var rocket = 'ss4i899';
import Image from 'next/image';
import {
  filledButtonDisabledStyle,
  filledButtonStyle,
  outlinedButtonStyle,
  pillButtonDisabledStyle,
  pillButtonStyle,
  textLinkStyle,
  textLinkTextStyle,
} from './Button.css';

export default function FilledButton({
  children,
  onClick,
  enabled = true,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  enabled?: boolean;
}) {
  if (enabled) {
    return (
      <div className={filledButtonStyle} onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return <div className={filledButtonDisabledStyle}>{children}</div>;
  }
}

export function OutlineButton({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <div className={outlinedButtonStyle} onClick={onClick}>
      {children}
    </div>
  );
}

export function PillButton({
  children,
  onClick,
  enabled = true,
}: {
  children: React.ReactNode;
  onClick?: () => void;
  enabled?: boolean;
}) {
  if (enabled) {
    return (
      <div className={pillButtonStyle} onClick={onClick}>
        {children}
      </div>
    );
  } else {
    return <div className={pillButtonDisabledStyle}>{children}</div>;
  }
}

export function TextLink({
  children,
  onClick,
}: {
  children: React.ReactNode;
  onClick: () => void;
}) {
  return (
    <div className={textLinkStyle} onClick={onClick}>
      <div className={textLinkTextStyle}>{children}</div>
      <Image src="/images/ic_right_arrow.png" alt="" width={8} height={14} />
    </div>
  );
}

import { fullImageStyle } from './Util.css';
import Image from 'next/image';

export function FullImage({
  src,
  alt,
}: {
  src: string;
  alt: string;
}) {
  return <Image src={src} alt={alt} fill className={fullImageStyle} />;
}

import { sectionContainerStyle, youtubeContainerStyle, youtubeIframeStyle } from './Container.css';

export function SectionContainer({ children, id }: { children: React.ReactNode; id?: string }) {
  return (
    <div className={sectionContainerStyle} id={id}>
      {children}
    </div>
  );
}

export function YoutubeContainer({ src }: { src: string }) {
  return (
    <div className={youtubeContainerStyle}>
      <iframe
        className={youtubeIframeStyle}
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen
      ></iframe>
    </div>
  );
}

'use client';
import { TextLink } from '@components/common/Button';
import { SectionContainer } from '@components/common/Container';
import { H2Text } from '@components/common/Typography';
import VerticalSpace from '@components/common/VerticalSpace';
import { useState } from 'react';
import { useNavigate } from 'utils/navigate';
import {
  answerContainerStyle,
  markStyle,
  questionContainerStyle,
  questionLinkStyle,
} from './_questions.css';

type QAndA = {
  question: string;
  answer: React.ReactNode;
};

const questionsAndAnswers: QAndA[] = [
  {
    question: 'CNPトレカの発売時期はいつですか？',
    answer: (
      <div>
        CNPトレカの具体的な発売時期については、公式サイトやSNSでの最新情報をご確認ください。詳細が決まり次第、公式アナウンスでお知らせいたします。
      </div>
    ),
  },
  {
    question: 'CNPトークンとCNPトレカパスの違いは何ですか',
    answer: (
      <div>
        CNPトークンは、コミュニティ内での様々な活動に利用できるトークンで、FiNANCiEプラットフォーム上で取引可能です。一方、CNPトレカパスは、CNPトレカの優先購入権や限定特典を提供する年間パスです。
      </div>
    ),
  },
  {
    question: 'CNPトレカパスを購入するメリットは何ですか？',
    answer: (
      <div>
        CNPトレカパスを購入することで、限定商品が購入できたり、イベントの優先参加権など、さまざまな特典を享受できます。また、年間を通じて新しいコンテンツや特典が追加される予定です。
        <a href="https://market.alpha-u.io/collections/CNP_TCG_PASS">
          詳しくはトレカパスの販売ページをご参照ください
        </a>
      </div>
    ),
  },
  {
    question: 'CNPトレカの特徴は何ですか？',
    answer: (
      <div>
        CNPトレカは、ブロックチェーン技術を活用した、希少性証明付きの物理的なトレカとして提供されます。これにより、カードの希少性や所有権が確実に保証され、トレカファン（プレイヤー、コレクター）に新しい体験を提供します。また、本格的な競技トレカの側面も持ち合わせています。
      </div>
    ),
  },
];

function QuestionItem({ qAndA }: { qAndA: QAndA }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div
        className={questionContainerStyle}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div className={markStyle}>Q</div>
        <div className={questionLinkStyle}>{qAndA.question}</div>
      </div>
      {isOpen && (
        <>
          <VerticalSpace size="11" />
          <div className={answerContainerStyle}>
            <div className={markStyle}>A</div>
            {qAndA.answer}
          </div>
        </>
      )}

      <VerticalSpace size="26" />
    </>
  );
}

export default function Questions() {
  const { navigateToExternal } = useNavigate();
  return (
    <SectionContainer id="questions">
      <VerticalSpace size="60" />
      <H2Text>よくある質問</H2Text>
      <VerticalSpace size="26" />
      {questionsAndAnswers.map((qAndA, index) => (
        <QuestionItem qAndA={qAndA} key={index} />
      ))}
      <VerticalSpace size="26" />
      <TextLink
        onClick={() => {
          navigateToExternal('note');
        }}
      >
        よくある質問はこちら（公式CNPnoteへ）
      </TextLink>
      <VerticalSpace size="60" />
    </SectionContainer>
  );
}

import "../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22components%2Fcommon%2FTypography.css.ts.vanilla.css%22%2C%22source%22%3A%22Ll8xZmJqOXozMCB7CiAgZm9udC1zaXplOiAzNnB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7CiAgbGluZS1oZWlnaHQ6IDEuNDsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLl8xZmJqOXozMSB7CiAgZm9udC1zaXplOiAzMnB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7CiAgbGluZS1oZWlnaHQ6IDEuNTsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLl8xZmJqOXozMiB7CiAgZm9udC1zaXplOiAyOHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7CiAgbGluZS1oZWlnaHQ6IDEuNTsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLl8xZmJqOXozMyB7CiAgZm9udC1zaXplOiAyMHB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7CiAgbGluZS1oZWlnaHQ6IDEuNTsKICB0ZXh0LWFsaWduOiBjZW50ZXI7Cn0KLl8xZmJqOXozNCB7CiAgZm9udC1zaXplOiA0OHB4OwogIGZvbnQtd2VpZ2h0OiAyMDA7CiAgbGluZS1oZWlnaHQ6IDY1cHg7CiAgdGV4dC1hbGlnbjogY2VudGVyOwp9Ci5fMWZiajl6MzUgewogIGZvbnQtc2l6ZTogMTZweDsKICBmb250LXdlaWdodDogMzAwOwogIGxpbmUtaGVpZ2h0OiAxNnB4OwogIHRleHQtYWxpZ246IGNlbnRlcjsKfQouXzFmYmo5ejM2IHsKICBmb250LXNpemU6IDI0cHg7CiAgZm9udC13ZWlnaHQ6IDcwMDsKICBsaW5lLWhlaWdodDogMS41Owp9Ci5fMWZiajl6MzcgewogIGZvbnQtc2l6ZTogMTZweDsKICBmb250LXdlaWdodDogNzAwOwogIGxpbmUtaGVpZ2h0OiAxLjU7Cn0KLl8xZmJqOXozOCB7CiAgZm9udC1zaXplOiAxNnB4OwogIGZvbnQtd2VpZ2h0OiA0MDA7CiAgbGluZS1oZWlnaHQ6IDEuNTsKICB0ZXh0LWFsaWduOiBqdXN0aWZ5Owp9Ci5fMWZiajl6MzkgewogIGZvbnQtc2l6ZTogMTRweDsKICBmb250LXdlaWdodDogNzAwOwogIGxpbmUtaGVpZ2h0OiAxLjU7Cn0KLl8xZmJqOXozYSB7CiAgZm9udC1zaXplOiAxMnB4OwogIGZvbnQtd2VpZ2h0OiA3MDA7CiAgbGluZS1oZWlnaHQ6IDEuNzsKfQ%3D%3D%22%7D"
export var boldText = '_1fbj9z37';
export var decorativeLabel = '_1fbj9z3a';
export var h1Text = '_1fbj9z30';
export var h2Text = '_1fbj9z31';
export var h3Text = '_1fbj9z32';
export var h5Text = '_1fbj9z33';
export var label = '_1fbj9z39';
export var largeText = '_1fbj9z36';
export var memberHeaderText = '_1fbj9z34';
export var memberSubHeaderText = '_1fbj9z35';
export var regularText = '_1fbj9z38';
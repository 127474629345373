'use client';

import { OutlineButton } from '@components/common/Button';
import { SectionContainer } from '@components/common/Container';
import { H2Text } from '@components/common/Typography';
import { FullImage } from '@components/common/Util';
import VerticalSpace from '@components/common/VerticalSpace';
import { useNavigate } from 'utils/navigate';

export default function FeaturedProduct() {
  const { navigateToInternal } = useNavigate();
  return (
    <SectionContainer>
      <VerticalSpace size="60"></VerticalSpace>
      <H2Text>商品情報</H2Text>
      <VerticalSpace size="11"></VerticalSpace>
      <FullImage src="/images/products/1/box.png" alt="" />
      <VerticalSpace size="18"></VerticalSpace>
      <OutlineButton onClick={() => navigateToInternal('products/$id', '1')}>
        商品情報をくわしく見る
      </OutlineButton>
    </SectionContainer>
  );
}

import { createClient } from 'microcms-js-sdk';

export function getClient() {
  const apiKey = process.env.NEXT_PUBLIC_MICRO_CMS_API_KEY;
  if (apiKey === undefined) {
    throw new Error('NEXT_PUBLIC_MICRO_CMS_API_KEY is not defined');
  }
  return createClient({
    serviceDomain: '8r78ds6xwq',
    apiKey: apiKey,
  });
}

'use client';

import { TextLink } from '@components/common/Button';
import { SectionContainer, YoutubeContainer } from '@components/common/Container';
import { H2Text, RegularText } from '@components/common/Typography';
import VerticalSpace from '@components/common/VerticalSpace';
import { useNavigate } from 'utils/navigate';

export default function Appraisal() {
  const { navigateToInternal } = useNavigate();
  return (
    <SectionContainer id="appraisal">
      <VerticalSpace size="60" />
      <H2Text>独自の鑑定機能</H2Text>
      <VerticalSpace size="11" />
      <YoutubeContainer src="https://www.youtube.com/embed/rOtxjwvunRg?si=JHOHiQS5JQWGMSUI" />
      <VerticalSpace size="11" />
      <RegularText>
        希少性証明カード（RP
        Card）には、NFCタグが埋め込まれています。NFCには、固有のシリアル番号が割り当てられ、ブロックチェーン上に発行されたデジタルカードと紐づけが行われています。このテクノロジーにより、PRカードの発行枚数（希少性）の透明性と流通量の管理が可能となります。
      </RegularText>
      <VerticalSpace size="18"></VerticalSpace>
      <TextLink
        onClick={() => {
          navigateToInternal('appraisal');
        }}
      >
        鑑定機能が作る新しい体験とは
      </TextLink>
    </SectionContainer>
  );
}
